// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#SponsorsPage-module-main img {\n  max-height: 64px;\n  object-fit: contain;\n  max-width: 149px;\n}\n\n#SponsorsPage-module-main ul {\n  list-style: none;\n  gap: 50px;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0;\n  max-width: 1337px;\n  margin: 0 auto 40px;\n  padding: 0 20px;\n}\n\n#SponsorsPage-module-main > h1 {\n  text-align: center;\n  margin-bottom: 50px;\n}\n\n.SponsorsPage-module-noNames, .SponsorsPage-module-noNames li {\n  display: flex;\n  justify-content: center;\n}\n\n#SponsorsPage-module-main li section {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/SponsorsPage/SponsorsPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,aAAa;EACb,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB","sourcesContent":["#main img {\n  max-height: 64px;\n  object-fit: contain;\n  max-width: 149px;\n}\n\n#main ul {\n  list-style: none;\n  gap: 50px;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0;\n  max-width: 1337px;\n  margin: 0 auto 40px;\n  padding: 0 20px;\n}\n\n#main > h1 {\n  text-align: center;\n  margin-bottom: 50px;\n}\n\n.noNames, .noNames li {\n  display: flex;\n  justify-content: center;\n}\n\n#main li section {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "SponsorsPage-module-main",
	"noNames": "SponsorsPage-module-noNames"
};
export default ___CSS_LOADER_EXPORT___;
