// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#AgendaPage-module-agenda {\n  padding: 20px;\n}\n\n#AgendaPage-module-agenda > ol {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 15px;\n}\n\n#AgendaPage-module-agenda > ol > li {\n  min-width: 285px;\n}\n\n#AgendaPage-module-agenda ol {\n  padding: 0;\n}\n\n#AgendaPage-module-agenda ol > li {\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (min-width: 729px) {\n\n  #AgendaPage-module-agenda > ol > li {\n    flex-direction: row;\n    justify-content: space-between;\n    min-width: 655px;\n  }\n\n  #AgendaPage-module-agenda > ol > li li, #AgendaPage-module-agenda > ol > li span {\n    text-align: right;\n  }\n\n}", "",{"version":3,"sources":["webpack://./src/components/AgendaPage/AgendaPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE;IACE,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;AAEF","sourcesContent":["#agenda {\n  padding: 20px;\n}\n\n#agenda > ol {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 15px;\n}\n\n#agenda > ol > li {\n  min-width: 285px;\n}\n\n#agenda ol {\n  padding: 0;\n}\n\n#agenda ol > li {\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (min-width: 729px) {\n\n  #agenda > ol > li {\n    flex-direction: row;\n    justify-content: space-between;\n    min-width: 655px;\n  }\n\n  #agenda > ol > li li, #agenda > ol > li span {\n    text-align: right;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agenda": "AgendaPage-module-agenda"
};
export default ___CSS_LOADER_EXPORT___;
